import firebase from "@/firebase";


export const getCompanyList = async () => {
    const snapCompany = await firebase.firestore()
        .collection("companies")
        .where("isMaster", "==", true)
        .orderBy('name')
        .get();
    return snapCompany.docs.map((doc) => {
        return {
            id: doc.id,
            name: doc.data().name
        }
    })
}

export const getIndustryList = async () => {
    const snapIndustry = await firebase.firestore()
        .collection("industries")
        .where("isMaster", "==", true)
        .orderBy('name')
        .get();
    return snapIndustry.docs.map((doc) => {
        return {
            id: doc.id,
            name: doc.data().name
        }
    })
}
