<template>
  <div class='company-input'>
    <select-element
      :label='label'
      :placeholder='placeholder'
      :error='companyText !== "*" ? error : ""'
      :disabled='disabled'
      :options='options.concat({
        text: "Other",
        value: "*"
      })'
      :filter='customFilter'
      :model-value='companyText'
      @focus='$emit("focus", $event)'
      @blur='$emit("blur", $event)'
      @update:model-value='update($event, companyOtherText)'/>
    <input-element class='otc'
      type='text'
      v-show='companyText === "*"'
      :label='otherLabel'
      :error='error'
      :disabled='disabled'
      :model-value='companyOtherText'
      @focus='$emit("focus", $event)'
      @blur='$emit("blur", $event)'
      @update:model-value='update(companyText, $event)'/>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import companies from './companies.json'

  export default {
    props: {
      label: String,
      otherLabel: String,
      placeholder: String,
      error: String,
      disabled: Boolean,
      company: String,
      industry: String
    },

    emits: [
      'focus',
      'blur',
      'update:company',
      'update:industry'
    ],

    setup (props, context) {
      let options = companies.map(({ name }) => ({
        text: name,
        value: name
      }))

      let customFilter = (option, pattern) => {
        if (option.value === '*') return true
        return pattern.test(option.text)
      }

      let companyText = computed(() => {
        if (!props.company) return ''
        return companies.find(({ name }) => name === props.company)
          ? props.company
          : '*'
      })

      let companyOtherText = computed(() => {
        if (companyText.value !== '*' || props.company === '-') return ''
        return props.company
      })

      let update = (text, otherText) => {
        let company = text === '*' ? (otherText || '-') : text
        let industry = companies
          .find(({ name }) => name === company)
          ?.industry ?? '-'

        context.emit('update:company', company)
        context.emit('update:industry', industry)
      }

      return {
        options,
        customFilter,
        companyText,
        companyOtherText,
        update
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .otc {
    margin-top: 24px;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .otc {
      margin-top: 32px;
    }
  }
</style>
