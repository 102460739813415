<template>
  <div class="container-input-suggestion">
    <input-element
      type="text"
      :model-value="modelValue.value"
      :label="label"
      :error="error"
      @input="handleChange"
      @focus="inputFocus"
      @blur="inputBlur"
      @change="$emit('change')"
    />
    <transition name="ops">
      <div class="wrapper-dropdown" v-show="options.length > 0 && inputFocused">
        <div class="container-dropdown">
          <template
            v-for="option in options"
            :key="`${option.value}:${option.text}`"
          >
            <div
              class="item-option"
              :class="{ 'is-disabled': option.disabled }"
              @mousedown="
                handleSelected(
                  {
                    id: option.id,
                    value: option.text,
                  },
                  $event
                )
              "
            >
              <slot name="option" :option="option">
                <div class="ovf">{{ option.text }}</div>
              </slot>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  emits: ["handleChangeText", "update:modelValue", "blur", "change"],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: Object,
    label: {
      type: String,
      default: "",
    },
    error: String,
  },

  setup(props, context) {
    let inputFocused = ref(false);

    let inputFocus = (event) => {
      inputFocused.value = true;
    };

    let inputBlur = (event) => {
      inputFocused.value = false;
      context.emit("blur");
    };

    let handleSelected = (selected) => {
      context.emit("update:modelValue", selected);
    };

    let handleChange = (e) => {
      const value = e.target.value;
      context.emit("update:modelValue", {
        value: value,
      });
    };

    return {
      inputFocused,
      inputFocus,
      handleSelected,
      handleChange,
      inputBlur,
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/variables';
.container-input-suggestion {
  position: relative;

  .wrapper-dropdown {
    position: absolute;
    right: 0;
    left: 0;
    z-index: map.get(variables.$elevations, "menu");

    &-enter-active,
    &-leave-active {
      transition: opacity 0.2s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }

  .container-dropdown {
    overflow-y: auto;
    margin-bottom: 24px;
    padding: 8px 0;
    border-right: 1px solid map.get(variables.$colors, "gray", "300");
    border-bottom: 1px solid map.get(variables.$colors, "gray", "300");
    border-left: 1px solid map.get(variables.$colors, "gray", "300");
    max-height: min(150px, 60vh);
    box-sizing: border-box;
    background-color: white;
  }

  .item-option {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 32px;
    cursor: pointer;

    &:hover {
      background-color: map.get(variables.$colors, "gray", "100");
    }

    &.is-disabled {
      color: map.get(variables.$colors, "gray", "300");
      pointer-events: none;
    }
  }
}
</style>
